import { db } from '../firebase/';
import { leagueLeaderboardStore, userStore } from '../stores.js';
import {
  collection,
  doc,
  getDoc,
  addDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  where,
  orderBy,
  getDocs,
  query,
  select,
} from 'firebase/firestore';

let user;
userStore.subscribe((value) => {
  user = value;
});

const userCollection = '__users_collection__';

export const updateLeagueLeaderboard = async (round, userIds) => {
  const roundKey = round === 'overall' ? 'overall' : `round_${round}`;
  const leaderboardRef = doc(
    db,
    '__meta_collection__',
    'season',
    'leaderboards',
    `${roundKey}`
  );
  const leaderboardDoc = await getDoc(leaderboardRef);
  if (!leaderboardDoc.exists()) return [];
  const leaderboardData = parseLeaderboardData(leaderboardDoc, userIds);
  leagueLeaderboardStore.update((all) => ({ ...all, leaderboardData }));
  return leaderboardData;
}

export const getLeagueLeaderboard = async (leagueId, round = 'overall') => {
  const roundKey = round === 'overall' ? 'overall' : `round_${round}`;
  const leagueRef = doc(db, '__leagues_collection__', leagueId);
  const leagueSnap = await getDoc(leagueRef);
  if (!leagueSnap.exists) return null;
  const leagueData = leagueSnap.data();
  const userIds = leagueData.users;
  const users = await Promise.all(
    userIds.map(async (userId) => {
      const userRef = doc(db, userCollection, userId);
      let userDoc = await getDoc(userRef);
      return userDoc.data();
    })
  );

  const leaderboardRef = doc(
    db,
    '__meta_collection__',
    'season',
    'leaderboards',
    `${roundKey}`
  );

  const leaderboardDoc = await getDoc(leaderboardRef);

  // if (!leaderboardDoc.exists()) return [];
  let leaderboardData = leaderboardDoc.exists() ? parseLeaderboardData(leaderboardDoc, userIds) : [];

  const tips = await Promise.all(
    userIds.map(async (userId) => {
      const tipsQuery = query(
        collection(db, userCollection, userId, 'tips'),
        where('winner', '==', null)
      );
      const tipsSnap = await getDocs(tipsQuery);
      return tipsSnap.docs.map((doc) => ({ userId, ...doc.data() }));
    })
  );
  // console.log('tips', tips.flat());
  // PICK UP HERE TO ENSURE SORTING IS DONE BY GAME NOT STARTTIME THAT CAN CHANGE!
  // turns out this wasn't a but, it was because games added for finals had an incorrect
  // starttime value, so they were being sorted to the top and then changing later
  leagueLeaderboardStore.set({
    leaderboardData,
    leagueData,
    users,
    tips: tips.flat().sort((a, b) => a.starttime - b.starttime),
  });
  return;
};

function parseLeaderboardData (leaderboardDoc, userIds) {
  return Object.entries(leaderboardDoc.data())
    .filter(([key, value]) => userIds.includes(key))
    .map(([key, value], i) => {
      // this is a unique case for the 'overall' leaderboard - round leadeboard data has pending, but not walletTotal
      if (value.wT || value.wT === 0) {
        return {
          id: key,
          name: value.n,
          walletTotal: value.wT,
          netWorth: value.nW.toFixed(2).replace(/[.,]00$/, ""),
          form: value.f.reduce((acc, curr) => acc + `${curr} `, ''),
          pending: (value.nW - value.wT).toFixed(2).replace(/[.,]00$/, ""),
        };
      }
      // for individual round leaderboards, we won't have a netWorth in the data until after the first match is finalised
      if (!value.nW) value.nW = 0;
      return {
        id: key,
        name: value.n,
        netWorth: value.nW.toFixed(2).replace(/[.,]00$/, ''),
        pending: value.p.toFixed(2).replace(/[.,]00$/, ''),
      };
    })
    .sort((a, b) => {
      // Compare by 'networth' first
      if (a.netWorth !== b.netWorth) {
        return b.netWorth - a.netWorth;
      }
      // If 'networth' is equal, compare by 'name'
      return a.name.localeCompare(b.name);
    })
    .map((item, i) => ({ ...item, rank: i + 1 }));
}

export const createNewLeague = async (userId, leagueName) => {
  // Add a new document with a generated id.
  const timestamp = new Date().getTime();
  const docRef = await addDoc(collection(db, '__leagues_collection__'), {
    name: leagueName,
    users: [userId],
    created: timestamp,
    updated: timestamp,
  });
  return docRef.id;
};

export const joinLeague = async (leagueId) => {
  /**
   * 1. Checks if league code is real
   * 2. If a real league code, add the league doc id to users 'leagues' array
   * 3. Add the user information to the league doc users array
   */
  const timestamp = new Date().getTime();
  const leagueRef = doc(db, '__leagues_collection__', leagueId);
  const leagueSnap = await getDoc(leagueRef);
  if (!leagueSnap.exists) {
    throw new Error('No league doc found!');
  }
  // We're not bothering the check if IDs have already been added to these arrays
  // as arrayUnion won't add elements that are already present
  await updateDoc(leagueRef, {
    users: arrayUnion(user.id),
    updated: timestamp,
  });
  const userRef = doc(db, userCollection, user.id);
  await updateDoc(userRef, {
    leagues: arrayUnion(leagueId),
    updated: timestamp,
  });
};

export const leaveLeague = async (leagueId) => {
  /**
   * 1. Remove the user ID from the league doc's users array
   * 2. Remove the league doc id from the user's leagues array
   */
  const timestamp = new Date().getTime();
  const leagueRef = doc(db, '__leagues_collection__', leagueId);
  await updateDoc(leagueRef, {
    users: arrayRemove(user.id),
    updated: timestamp,
  });
  const userRef = doc(db, userCollection, user.id);
  await updateDoc(userRef, {
    leagues: arrayRemove(leagueId),
    updated: timestamp,
  });
};
