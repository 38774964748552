<script>
  export let id;
  import {
    VerticalSpacer,
    BlogCard,
    Divider,
    Heading,
    Text,
    PageContainer,
    LoadingText,
  } from '../components/ui';
  import { onMount } from 'svelte';
  import { getBlogPostById } from '../utils/blog-posts';
  import { blogPosts } from '../stores';
  import { fade } from 'svelte/transition';
  import ConfettiOverlay from '../components/effects/ConfettiOverlay.svelte';
  let html,
    title,
    published,
    type,
    author = '',
    pageEffect = null;

  let loaded = null;

  $: {
    getBlogPostById(id).then((blogPost) => {
      html = blogPost.content;
      title = blogPost.title;
      published = blogPost.published;
      type = blogPost.type;
      author = blogPost.author;
      pageEffect = blogPost.pageEffect || null;
      if (title && html) loaded = true;
    });
  }
</script>

{#if loaded}
  <div in:fade={{ delay: 100, duration: 500 }}>
    <PageContainer>
      <VerticalSpacer height="20px" />
      <div class="highlight">
        <Text>{type}</Text>
      </div>
      <VerticalSpacer height="20px" />
      <Heading fontSize="20px">{title}</Heading>
      <Text fontColor="#999"
        >by {author}, {new Date(published).toDateString()}</Text
      >
      <div class="local">
        <VerticalSpacer height="30px" />
        {@html html}
        <VerticalSpacer height="200px" />
      </div>

      <Divider />
      <VerticalSpacer height="20px" />
      {#if $blogPosts.length > 0}
        <Heading>More recent stuff to read...</Heading>
        <VerticalSpacer height="20px" />
      {/if}
      {#each $blogPosts.filter((p) => p.id !== id) as post}
        {#if post.published}
          <BlogCard
            image={post.image}
            link={`/blog/${post.id}`}
            title={post.title}
            published={post.published}
          />
        {/if}
      {/each}
    </PageContainer>
    {#if pageEffect === 'confetti'}
      <ConfettiOverlay />
    {/if}
  </div>
{:else}
  <PageContainer>
    <div in:fade={{ delay: 100, duration: 500 }}>
      <LoadingText />
    </div>
  </PageContainer>
{/if}

<style>
  .local :global(p) {
    font-family: 'Source Code Pro', monospace;
    text-align: justify;
    /* font-family: 'Montserrat', sans-serif; */
  }

  .local :global(img) {
    width: 100%;
    position: relative;
    top: -10px;
    left: -10px;
    border: #999 solid 1px;
  }

  .local :global(.image-border) {
    position: relative;
    top: 5px;
    left: 5px;
    background-color: #fede47;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .highlight {
    background-color: #ffde46;
    display: inline-block;
    padding: 0px 8px;
    border-radius: 8px;
  }

  .local :global(h1) {
    font-size: 2em;
    font-weight: 600;
    margin: 0px 0px 0px 0px;
  }

  /* pulling in the styles for the ThickBorder for tables doesn't want to work... */
  .local :global(table) {
    border-collapse: initial;
    color: #333333;
    width: 100%;
    text-align: center;
    border: black solid 2px;
    border-image-slice: 3;
    border-image-width: 3;
    border-image-repeat: stretch;
    border-image-source: url('data:image/svg+xml;utf8,<?xml version="1.0" encoding="UTF-8" ?><svg version="1.1" width="8" height="8" xmlns="http://www.w3.org/2000/svg"><path d="M3 1 h1 v1 h-1 z M4 1 h1 v1 h-1 z M2 2 h1 v1 h-1 z M5 2 h1 v1 h-1 z M1 3 h1 v1 h-1 z M6 3 h1 v1 h-1 z M1 4 h1 v1 h-1 z M6 4 h1 v1 h-1 z M2 5 h1 v1 h-1 z M5 5 h1 v1 h-1 z M3 6 h1 v1 h-1 z M4 6 h1 v1 h-1 z" fill="rgb(213,213,213)" /></svg>');
    border-image-outset: 2;
  }

  /* This works but is impacting tables etc. also */
  /* .local :global(p:first-of-type:first-letter) {
    float: left;
    font-size: 75px;
    line-height: 60px;
    padding-top: 4px;
    padding-right: 8px;
    padding-left: 3px;
  } */

  .local :global(td) {
    text-align: center;
    font-family: 'Source Code Pro', monospace;
    background-color: #fafafa;
    font-weight: 600;
    padding-top: 16px;
  }

  .local :global(h3) {
    margin-bottom: -5px;
    margin-top: 30px;
    font-size: 16px;
  }
</style>
