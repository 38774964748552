<script>
  import { onMount } from 'svelte';

  onMount(() => {
    const confettiContainer = document.querySelector('.confetti-overlay');
    const confettiColors = [
      '#FFC107',
      '#E91E63',
      '#9C27B0',
      '#3F51B5',
      '#2196F3',
      '#4CAF50',
      '#FFEB3B',
      '#FF5722',
    ];

    function createConfettiPiece() {
      const confetti = document.createElement('div');
      confetti.classList.add('confetti-piece');
      confetti.style.backgroundColor =
        confettiColors[Math.floor(Math.random() * confettiColors.length)];
      confetti.style.left = `${Math.random() * 100}%`;
      confetti.style.animationDuration = `${Math.random() * 3 + 2}s`;
      confettiContainer.appendChild(confetti);

      setTimeout(() => {
        confetti.remove();
      }, 4000);
    }

    // Create initial confetti
    for (let i = 0; i < 100; i++) {
      setTimeout(createConfettiPiece, Math.random() * 4000);
    }

    // Create confetti at intervals
    const confettiInterval = setInterval(() => {
      createConfettiPiece();
    }, 500);

    // Stop all confetti after 10 seconds
    setTimeout(() => {
      clearInterval(confettiInterval);
    }, 20000);

    // Cleanup interval on component unmount
    return () => clearInterval(confettiInterval);
  });
</script>

<div class="confetti-overlay"></div>

<style>
  .confetti-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 9999;
    overflow: hidden;
  }

  /* Mark the styles as global as svelte css scoping won't work for elements that are dynamically generated */
  :global(.confetti-piece) {
    position: absolute;
    width: 10px;
    height: 20px;
    background-color: red;
    opacity: 0.8;
    animation: fall 4s infinite ease-in;
  }

  @keyframes fall {
    0% {
      transform: translateY(-100%) rotate(0deg);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    100% {
      transform: translateY(100vh) rotate(720deg);
      opacity: 0;
    }
  }
</style>
